@font-face {
  font-family: 'Ploni';
  src: url('./Ploni/Ploni-Black.otf');
  font-weight: 900;
}

@font-face {
  font-family: 'Ploni';
  src: url('./Ploni/Ploni-Bold.otf');
  font-weight: 700;
}

@font-face {
  font-family: 'Ploni';
  src: url('./Ploni/Ploni-D-Bold.otf');
  font-weight: 600;
}

@font-face {
  font-family: 'Ploni';
  src: url('./Ploni/Ploni-Light.otf');
  font-weight: 300;
}

@font-face {
  font-family: 'Ploni';
  src: url('./Ploni/Ploni-Medium.otf');
  font-weight: 500;
}

@font-face {
  font-family: 'Ploni';
  src: url('./Ploni/Ploni-Regular.otf');
  font-weight: 400;
}

@font-face {
  font-family: 'Ploni';
  src: url('./Ploni/Ploni-UBold.otf');
  font-weight: 800;
}

@font-face {
  font-family: 'Ploni';
  src: url('./Ploni/Ploni-ULight.otf');
  font-weight: 200;

}

/* Default font settings */
body {
  font-family: 'Rubik', sans-serif;
}
